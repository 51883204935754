import React from 'react';

const LockADeal = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>How long you lock in a deal depends on your circumstances and risk-tolerance</li>
              <li>
              A longer fixed mortgage offers security for that mortgage term
              </li>
              <li>
                The most common mortgage terms are 2 and 5 years
              </li>
              <li>
              If you’re expecting big life changes or moving home in the near future a shorter-term mortgage may be a better option
              </li>
              <li>
              Economic factors are important too if you’re expecting changes to the market and interest rates to increase or decrease in the future
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              How long should I lock in a mortgage deal?<br/><br/>
              Deciding how long to lock in your mortgage deal can be a different decision, as it depends on your individual circumstances and risk tolerance.<br /><br />
              What you get for fixing a mortgage is security for that period of time. Generally, lenders offer fixed products anywhere from one - to ten years, with the most common being between a two-year and a five-year fixed.<br /><br />
              If you're looking for more stability and predictability in your mortgage payments, a longer initial period may be a better choice.<br /><br />
              For example, a two or five-year deal may offer a higher interest rate but also provide greater protection against potential rate increases during that period. <br /><br />
              If, for example, you have an idea of something happening in your life in the next few years, fixing it for too long will leave you liable to pay early repayment charges to get out of that mortgage. In that case, you may opt for a shorter fixed-rate mortgage than someone else who has complete certainty for the next five years.<br /><br />
              As well as personal circumstances, there are also economic and financial changes to the markets that may make people decide to fix for a set period of time.<br /><br />
              Ultimately, the decision of how long to lock in your mortgage deal depends on your individual circumstances and priorities. It's really important to weigh the potential benefits and risks of each option.<br /><br />
              You should speak to a mortgage adviser to help you make an informed decision.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default LockADeal;
